import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink, UncontrolledTooltip, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import classnames from "classnames";
import { connect, useSelector, useDispatch } from "react-redux";
import { getLoggedInUser } from '../../helpers/authUtils';
import { setActiveTab, initPage } from "../../redux/actions";
import config_empresa from "../../config_empresa"
import { AiOutlineFileSearch } from "react-icons/ai"

//Import Images
import logo from "../../assets/images/robot.png"
import defaultAvatar from "../../assets/images/users/user.png"
import logoZapResponder from "../../assets/images/zapresponder/logo.png"

//i18n
import i18n from '../../i18n';

// falgs
const LAYOUT = {
    "zapresponder": { logo: logoZapResponder, name: "Zap Responder" },
    "multdesk": { logo: logo, name: "Multdesk" }
}


function LeftSidebarMenu(props) {

    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
    const { fila, totalFila } = useSelector(state => state.Chat)
    const { isAdmin } = useSelector(state => state.Auth)


    const { photo } = getLoggedInUser()


    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
    const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

    const toggleTab = tab => {
        props.setActiveTab(tab)
    }

    const activeTab = props.activeTab;


    return (
        <React.Fragment>
            <div className="side-menu flex-lg-column me-lg-1">
                {/* LOGO */}
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark" onClick={() => dispatch(initPage())}>
                        <span className="logo-sm">
                            <img src={LAYOUT[config_empresa.EMPRESA].logo} alt="logo" height="30" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light" onClick={() => dispatch(initPage())}>
                        <span className="logo-sm">
                            <img src={LAYOUT[config_empresa.EMPRESA].logo} alt="logo" height="30" />
                        </span>
                    </Link>
                </div>
                {/* end navbar-brand-box  */}

                {/* Start side-menu nav */}
                <div className="flex-lg-column my-auto">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        <NavItem id="Chats">
                            <NavLink id="pills-chat-tab" className={classnames({ active: activeTab === 'chat' })} onClick={() => { toggleTab('chat'); }}>
                                <i className="ri-message-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Chats" placement="top">
                            Chats
                        </UncontrolledTooltip>
                        {isAdmin &&
                            <React.Fragment>
                                <NavItem id="etiquetas-index">
                                    <NavLink id="etiquetas-nav" className={classnames({ active: activeTab === 'etiquetas-tab' })} onClick={() => { toggleTab('etiquetas-tab'); }}>
                                        <i className="ri-price-tag-3-line"></i>
                                    </NavLink>
                                </NavItem>
                                <UncontrolledTooltip target="etiquetas-index" placement="top">
                                    Etiquetas
                                </UncontrolledTooltip>
                            </React.Fragment>
                        }

                        <NavItem id="hist-message-tab">
                            <NavLink id="hist-message" className={classnames({ active: activeTab === 'hist-message' })} onClick={() => {
                                toggleTab('hist-message');
                            }}>
                                <AiOutlineFileSearch />
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="hist-message-tab" placement="top">
                            Históricos
                        </UncontrolledTooltip>


                        <NavItem id="Fila">
                            <NavLink id="pills-groups-tab" className={classnames({ active: activeTab === 'group' })} onClick={() => { toggleTab('group'); }}>
                                <span className="badge badge-soft-danger rounded-pill" style={{
                                    position: "absolute",
                                    marginTop: "20px",
                                    marginLeft: "20px",
                                    fontSize: "10px"
                                }}>{totalFila}</span>
                                <i className="ri-group-line">
                                </i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Fila" placement="top">
                            Fila
                        </UncontrolledTooltip>
                        <NavItem id="Contacts">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: activeTab === 'contacts' })} onClick={() => { toggleTab('contacts'); }}>
                                <i className="ri-contacts-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Contacts" placement="top">
                            Contatos
                        </UncontrolledTooltip>
                        <NavItem id="equipe">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: activeTab === 'equipe' })} onClick={() => { toggleTab('equipe'); }}>
                                <i className="ri-team-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="equipe" placement="top">
                            Equipe
                        </UncontrolledTooltip>

                        <NavItem id="fast-message-tab">
                            <NavLink id="fast-message" className={classnames({ active: activeTab === 'fast-message' })} onClick={() => { toggleTab('fast-message'); }}>
                                <i className="ri-mail-send-fill"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="fast-message-tab" placement="top">
                            Mensagens Rápidas
                        </UncontrolledTooltip>

                        <Dropdown nav isOpen={dropdownOpenMobile} toggle={toggleMobile} className="profile-user-dropdown d-inline-block d-lg-none">
                            <DropdownToggle nav>
                                <img src={photo || defaultAvatar} alt="chatvia" className="profile-user rounded-circle" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>Perfil <i class="ri-user-line float-end text-muted"></i> </DropdownItem>
                                <DropdownItem href="/logout">Sair <i className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* end side-menu nav */}

                <div className="flex-lg-column d-none d-lg-block">
                    <Nav className="side-menu-nav justify-content-center">
                        <Dropdown nav isOpen={dropdownOpen} className="nav-item btn-group dropup profile-user-dropdown" toggle={toggle}>
                            <DropdownToggle className="nav-link" tag="a">
                                <img src={photo || defaultAvatar} alt="" className="profile-user rounded-circle" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>Perfil <i class="ri-user-line float-end text-muted"></i> </DropdownItem>
                                <DropdownItem href="/logout">Sair <i className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* Side menu user */}
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        ...state.Layout
    };
};

export default connect(mapStatetoProps, {
    setActiveTab
})(LeftSidebarMenu);