import React, { useEffect } from 'react';
import Routes from './routes/';
import { Helmet } from "react-helmet"
import config from "./config_empresa"
//Import Scss
import "./assets/scss/themes.scss";
import { clearAllCache } from "./helpers/clear-cache";

//fackbackend
//import fakeBackend from './helpers/fake-backend';

// //Firebase helper
// import { initFirebaseBackend } from "./helpers/firebase";

// TODO
//fakeBackend();

// const firebaseConfig = {
// 	apiKey: process.env.REACT_APP_APIKEY,
// 	authDomain: process.env.REACT_APP_AUTHDOMAIN,
// 	databaseURL: process.env.REACT_APP_DATABASEURL,
// 	projectId: process.env.REACT_APP_PROJECTID,
// 	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
// 	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
// 	appId: process.env.REACT_APP_APPID,
// 	measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// // init firebase backend
// initFirebaseBackend(firebaseConfig);
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.substr(1);
}

function App() {
  useEffect(() => {
    const handleBeforeUnload = () => {
      clearAllCache()
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>{capitalize(config.EMPRESA)} - Chat</title>
      </Helmet>
      <Routes />
    </>
  );
}

export default App;
