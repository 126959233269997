import { v4 as uuidv4 } from "uuid";

export const requestPermission = () => {
    return new Promise((reject, resolve) => {
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
            reject({ error: true, message: "This browser does not support desktop notification" })
        } else {
            Notification.requestPermission()
                .then(success => {
                    console.log(success)
                    resolve({ error: false, message: success })
                })
                .catch(err => {
                    console.log(err)
                    reject({ error: true, message: err })
                });
        }
    })
}


export const showNotification = (data) => {
    try {

        var options = {
            body: data.body,
            icon: data.icon,
            dir: "ltr"
        };

        navigator.serviceWorker.getRegistration()
            .then(reg => {
                options.vibrate = [100, 50, 100]
                options.data = {
                    primaryKey: uuidv4()
                }

                reg.showNotification(data.title, options)
                    .then(success => console.log(success))
                    .catch(err => {
                        if (document.visibilityState == 'hidden') {
                            new Notification(data.title, options)
                        }
                    })
            })
            .catch(err => {
                console.log(err)
                if (document.visibilityState == 'hidden') {
                    new Notification(data.title, options)
                }
            })


    } catch (e) {
        console.log(e)
    }
}