import axios from 'axios';
import config from "./../config";


// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

try {
    axios.defaults.headers.common['version-chat'] = window.getVersion().versao
} catch (e) {
    axios.defaults.headers.common['version-chat'] = '0'
}

// intercepting to capture errors
axios.interceptors.response.use(function (response) {
    return response.data ? response.data : response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.response.status) {
        case 500: message = 'Internal Server Error'; break;
        case 401: message = 'E-mail e senha inválidos!'; break;
        default: message = error.response.data ? error.response.data.message : 'Internal Server Error' || error;
    }
    return Promise.reject(message);
});

/**
 * Sets the default authorization
 * @param {*} token 
 */
const setAuthorization = (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}


class APIClient {
    /**
     * Fetches data from given url
     */
    get = (url, params) => {
        return axios.get(url, params);
    }


    sendFile = (url, formData) => {
        return axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    /**
     * post given data to url
     */
    create = (url, data) => {
        return axios.post(url, data);
    }

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.put(url, data);
    }

    /**
     * Delete 
     */
    delete = (url) => {
        return axios.delete(url);
    }
}

export { APIClient, setAuthorization };