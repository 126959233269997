const clearCache = async () => {
  try {
    var names = await caches.keys()

    for (let name of names) {
      console.log(name)
      try {
        await caches.delete(name);
      } catch (e) {
        console.log(e)
      }
    }
  } catch (e) {
    console.log(e)
  }
}

const clearCacheServiceWorker = async () => {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations()

    for (let registration of registrations) {
      try {
        await registration.unregister()
      } catch (e) {
        console.log(e)
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export const clearAllCache = async () => {

  await clearCache()

  await clearCacheServiceWorker()
}
