import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
//import routes
import { authProtectedRoutes, publicRoutes } from './routes';

//import layouts
import NonAuthLayout from "../layouts/NonAuth";
import AuthLayout from "../layouts/AuthLayout/";
import { getLoggedInUser, isUserAuthenticated } from "../helpers/authUtils"
import { APIClient, setAuthorization } from "../helpers/apiClient"
import { useDispatch } from 'react-redux';
import { loginUserSuccess, logoutUser } from '../redux/actions';
import DialogAtualizacao from '../components/DialogUpdate'
import DialogFilesSize from '../components/DialogFileSize'

const api = new APIClient();

// handle auth and authorization
const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
    return <Route {...rest} render={props => {

        if (isAuthProtected && !isUserAuthenticated()) {
            return (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            );
        }
        // authorised so return component
        return <Layout><Component {...props} /></Layout>
    }} />
}

/**
 * Main Route component
 */
const Routes = (props) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [showFileStatus, setShowFileStatus] = useState(false)
    const [versao, setVersao] = useState()

    const [percentInUse, setPercentInUse] = useState(0)
    const [sizeFormated, setSizeFormated] = useState(0)
    const [limitSize, setLimitSize] = useState(0)


    useEffect(() => {
        // verifica se o token é valido, casou o token não seja válido
        const user = getLoggedInUser()

        if (!user || !user.token) {
            localStorage.removeItem("authUser")
            // window.location.href = '/login'
            dispatch(logoutUser())
            return false
        }

        setAuthorization(user.token)

        api.get("atendente")
            .then(success => {
                dispatch(loginUserSuccess(success))
            })
            .catch(err => {
                localStorage.removeItem("authUser")
                if (!window.location.pathname.includes("/login")) {
                    window.location.href = '/login'
                }
                dispatch(logoutUser())
            })


    }, [])

    useEffect(() => {
        api.get("/version/chat")
            .then(success => {
                if (success.versao != window.getVersion().versao) {
                    setVersao(success.versao)
                    setShow(true)
                }
            })
            .catch(err => err)
    }, [])

    useEffect(() => {
        api.get("atendente/fileStatus")
            .then(success => {
                if(success.percent_in_use > 93){
                    setLimitSize(success.limit_formated)
                    setSizeFormated(success.sizeFormated)
                    setPercentInUse(success.percent_in_use)
                    setShowFileStatus(true)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const clearCache = async () => {
        try {
            var names = await caches.keys()

            for (let name of names) {
                console.log(name)
                try {
                    await caches.delete(name);
                } catch (e) {
                    console.log(e)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const clearCacheServiceWorker = async () => {
        try {
            const registrations = await navigator.serviceWorker.getRegistrations()

            for (let registration of registrations) {
                try {
                    await registration.unregister()
                } catch (e) {
                    console.log(e)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleAtualizar = async () => {

        await clearCache()

        await clearCacheServiceWorker()

        window.location.reload(true)
    }

    return (
        // rendering the router with layout
        <BrowserRouter>
            <React.Fragment>
                <Suspense fallback={<div></div>} >
                    <Switch>
                        {/* public routes */}
                        {publicRoutes.map((route, idx) =>
                            <AppRoute path={route.path} layout={NonAuthLayout} component={route.component}
                                key={idx} isAuthProtected={false} />
                        )}

                        {/* private/auth protected routes */}
                        {authProtectedRoutes.map((route, idx) =>
                            <AppRoute path={route.path} layout={AuthLayout} component={route.component}
                                key={idx} isAuthProtected={true} />
                        )}
                    </Switch>
                </Suspense>
                <DialogAtualizacao
                    versao={versao}
                    handleAtualizar={handleAtualizar}
                    open={show}
                />

                <DialogFilesSize
                    limit_formated={limitSize}
                    sizeFormated={sizeFormated}
                    percent_in_use={percentInUse}
                    open={showFileStatus}
                    handleClose={ () => setShowFileStatus(false)}
                />
            </React.Fragment>
        </BrowserRouter>
    );
}

export default Routes;