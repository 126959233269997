const config = {
    //API_URL: "https://api.multdesk.com.br/",
    //URL_SOCKET: "https://websocket.multdesk.com.br",
    API_URL: process.env.REACT_APP_API_URL || "https://api.zapresponder.com.br/",
    URL_SOCKET: process.env.REACT_APP_URL_SOCKET || "https://websocket.zapresponder.com.br",
    // API_URL: "http://192.168.18.34:3001/",
    // URL_SOCKET: "http://192.168.18.34:3002/",
    APP_URL: process.env.REACT_APP_APP_URL || "https://app.zapresponder.com.br",
    AUTH_BACKEND: 'Dummy',
    API_STORAGE_URL: process.env.REACT_APP_STORAGE_URL || "https://storage.zapresponder.com.br/",
    STORAGE_URL: process.env.REACT_APP_STORAGE_URL || "https://storage.zapresponder.com.br/",
}

export default config
