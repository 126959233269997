import jwtDecode from 'jwt-decode';
import dateformat from 'dateformat'
import config from "../config"
import avatarDefault from "../assets/images/users/user.png"
import avatarRobo from "../assets/images/robot.png"

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    try {
        const decoded = jwtDecode(user.token);
        if (decoded._id) {
            console.warn('access token válido');
            return true;
        } else {
            localStorage.removeItem('authUser')
            console.warn('access token expired');
            return false;
        }
    } catch (e) {
        console.warn('access token expired');
        localStorage.removeItem('authUser')
        return false;
    }
}

/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
    localStorage.setItem('authUser', JSON.stringify(user));
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    return user ? (typeof (user) == 'object' ? user : JSON.parse(user)) : null;
}

const getFilter = () => {
    const filter = localStorage.getItem('filterUser');
    return filter ? (typeof (filter) == 'object' ? filter : JSON.parse(filter)) : {};
}

const setFilter = (filter) => {
    localStorage.setItem('filterUser', JSON.stringify(filter));
}

const getTypeFile = (fileName) => {
    if (fileName.includes("png") || fileName.includes("jpg") || fileName.includes("jpeg") || fileName.includes("webp")) {
        return 'image'
    } else if (fileName.includes("ogg") || fileName.includes("mp3")) {
        return 'audio'
    } else if (fileName.includes("mp4")) {
        return 'video'
    } else {
        return 'document'
    }
}

const isUrl = (url) => {
    if (url.includes("https://") || url.includes("http://")) {
        return url;
    } else {
        return config.STORAGE_URL + url
    }
}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const formaterMessage = (data, index, conversa, isAdmin = false) => {
    var messageObj;

    const { _id } = getLoggedInUser()

    var userType = "receive";

    if (["atendente", "bot"].includes(data.autor)) {
        if (conversa.isEquipe && data.atendente && data.atendente._id == _id) {
            userType = "sender"
        } else if (conversa.isGroup && data.atendente && data.atendente._id == _id) {
            userType = "sender"
        }
        // else if (data.atendente && data.atendente._id == _id) {
        //    userType = "sender"
        //}
        else if (data.autor.includes("bot")) {
            userType = "sender"
        } else if (data.atendente && !conversa.isEquipe && !conversa.isGroup) {
            userType = "sender"
        }
    }
    if (data.full_message && data.full_message.type === "call_timeout") {
        messageObj = {
            id: index,
            _id: data._id,
            isCall: true,
            userType: userType,
            content: `${data.full_message.content}  ${dateformat(data.createdAt, "dd-mm HH:MM")}`,
            time: dateformat(data.createdAt, "dd-mm HH:MM"),
        }
    } else if (data.mensagem.type === "text" || data.mensagem.type === "template") {

        var mensagem = data.mensagem.mensagem;

        if (data.mensagem.options) {
            mensagem = mensagem + "\n\n";

            data.mensagem.options.map(
                (option, index) =>
                    (mensagem += `${index + 1} - ${option.option}\n`)
            )
        }
        messageObj = {
            id: index,
            _id: data._id,
            message: mensagem,
            profilePicture: data.autor.includes("bot") ? avatarRobo : data.profilePicture,
            time: dateformat(data.createdAt, "dd-mm HH:MM"),
            createdAt: new Date(data.createdAt).getTime(),
            userType: userType,
            send_nome: data.autor.includes("atendente") ? data.atendente.nome : "Robô",
            userName: data.userName,
            messageType: data.mensagem.type,
            isFileMessage: false,
            isImageMessage: false,
            isRead: data.isRead,
            isRemoved: data.isRemoved
        }

    } else if (data.mensagem.type === "contact") {
        var contact = data.mensagem.mensagem.replace("[", "").replace("]", "").split(":");
        var mensagem = `CONTATO
                        Nome: ${contact[0]}
                        Telefone: ${contact[1]}
                        `

        messageObj = {
            id: index,
            _id: data._id,
            message: mensagem,
            profilePicture: data.autor.includes("bot") ? avatarRobo : data.profilePicture,
            time: dateformat(data.createdAt, "dd-mm HH:MM"),
            createdAt: new Date(data.createdAt).getTime(),
            userType: userType,
            send_nome: data.autor.includes("atendente") ? data.atendente.nome : "Robô",
            userName: data.userName,
            messageType: data.mensagem.type,
            isFileMessage: false,
            isImageMessage: false,
            isRead: data.isRead,
            isRemoved: data.isRemoved
        }

    } else {

        var fileName = data.mensagem.mensagem.split("/")
        fileName = fileName[fileName.length - 1].toLowerCase()

        if (fileName.includes("png") || fileName.includes("jpg") || fileName.includes("jpeg") || fileName.includes("webp")) {
            var imageMessage = [
                { image: isUrl(data.mensagem.mensagem), fileName },
            ]

            messageObj = {
                id: index,
                _id: data._id,
                message: data.mensagem.caption || data.mensagem.text_option,
                imageMessage: imageMessage,
                time: dateformat(data.createdAt, "dd-mm HH:MM"),
                userType: userType,
                createdAt: new Date(data.createdAt).getTime(),
                send_nome: data.autor.includes("atendente") ? data.atendente.nome : "Robô",
                isImageMessage: true,
                isFileMessage: false,
                isRead: data.isRead,
                userName: data.userName,
                src_location: data.mensagem.src_location,
                isRemoved: data.isRemoved

            }
        } else if (fileName.includes("ogg") || fileName.includes("mp3")) {

            messageObj = {
                id: index,
                _id: data._id,
                message: data.mensagem.caption,
                urlFile: isUrl(data.mensagem.mensagem),
                fileMessage: fileName,
                // size: message.size,
                createdAt: new Date(data.createdAt).getTime(),
                time: dateformat(data.createdAt, "dd-mm HH:MM"),
                userType: userType,
                send_nome: data.autor.includes("atendente") ? data.atendente.nome : "Robô",
                isFileMessage: false,
                isImageMessage: false,
                isAudio: true,
                isRead: data.isRead,
                userName: data.userName,
                isRemoved: data.isRemoved

            }

        } else if (fileName.includes("mp4")) {

            messageObj = {
                id: index,
                _id: data._id,
                message: data.mensagem.caption,
                urlFile: isUrl(data.mensagem.mensagem),
                fileMessage: fileName,
                // size: message.size,
                createdAt: new Date(data.createdAt).getTime(),
                time: dateformat(data.createdAt, "dd-mm HH:MM"),
                userType: userType,
                send_nome: data.autor.includes("atendente") ? data.atendente.nome : "Robô",
                isFileMessage: false,
                isImageMessage: false,
                isVideo: true,
                isRead: data.isRead,
                userName: data.userName,
                isRemoved: data.isRemoved
            }

        } else {
            messageObj = {
                id: index,
                _id: data._id,
                message: data.mensagem.caption,
                urlFile: isUrl(data.mensagem.mensagem),
                fileMessage: fileName,
                // size: message.size,
                createdAt: new Date(data.createdAt).getTime(),
                time: dateformat(data.createdAt, "dd-mm HH:MM"),
                userType: userType,
                send_nome: data.autor.includes("atendente") ? data.atendente.nome : "Robô",
                isFileMessage: true,
                isImageMessage: false,
                isRead: data.isRead,
                userName: data.userName,
                isRemoved: data.isRemoved
            }
        }
    }

    messageObj.isError = data.isError;
    if (data.reply) {
        if (data.reply._id)
            messageObj.reply = formaterMessage(data.reply, 0, conversa)
    }

    return messageObj;
}

const formaterConversa = (conversa, mensagens, lastIdUsers) => {

    var nome;
    var email;
    var contato_lead_id;
    var informacoesAdicionais;

    if (conversa.contato) {
        nome = conversa.contato.name
        email = conversa.contato.email
        contato_lead_id = conversa.contato._id
        informacoesAdicionais = conversa.contato.informacoesAdicionais

    } else if (conversa.lead) {
        nome = conversa.lead.nome
        email = conversa.lead.email
        contato_lead_id = conversa.lead._id
        informacoesAdicionais = conversa.lead.informacoesAdicionais
    }

    if (!nome) {
        nome = conversa.pushName || conversa.nome_grupo || conversa.chatId
    }

    var mensagens_formatadas = mensagens.map((row, indexMessage) => {
        if (row.atendente) {
            row.profilePicture = row.atendente.photo
        }

        if (row.autor.includes("bot")) {
            row.profilePicture = ""
        }

        return formaterMessage(row, indexMessage, conversa)
    })

    if (mensagens_formatadas.length >= 10) {
        mensagens_formatadas.unshift({ isToday: true })
    }

    var conversa_formated = {
        id: lastIdUsers + 1,
        chatId: conversa.chatId,
        _id: conversa._id,
        name: nome,
        profilePicture: conversa.profilePicture ? conversa.profilePicture.includes("https://api.") ? conversa.profilePicture : config.STORAGE_URL + conversa.profilePicture : '',
        status: conversa.status,
        unRead: 0,
        origem: conversa.origem,
        roomType: "contact",
        isGroup: false,
        isEquipe: false,
        atendente: conversa.atendente,
        etiquetas: conversa.etiquetas,
        contato_lead_id,
        email,
        informacoesAdicionais,
        messages: mensagens_formatadas,
        departamento_responsavel_atendimento: conversa.departamento_responsavel_atendimento
    }

    return conversa_formated

}


const getAllIndexes = (arr, val) => {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) != -1) {
        indexes.push(i);
    }
    return indexes;
}

const replaceBold = (message) => {
    var copyMessage = message;
    var boldRegex = /\*/g;

    var teste = message.match(boldRegex)

    if (!teste) return message;
    if (teste.length <= 1) return message;

    var allIndex = getAllIndexes(message, "*")

    if (allIndex.length % 2 != 0) {
        // tamanho do array é impar, elimina o ultimo index
        allIndex.pop()
    }

    for (let index = 0; index < allIndex.length; index++) {
        const element = allIndex[index];
        if (index % 2 == 0) {
            copyMessage = copyMessage.replace("*", "<b>")
        } else {
            copyMessage = copyMessage.replace("*", "</b>")
        }
    }

    return copyMessage
}

const replaceNewLine = (message) => {
    var newLineRegex = /\n/g;
    return message.replace(newLineRegex, "<br />")
}


const replaceURLs = (message) => {

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" target="_blank">' + url + '</a>'
    });
}

const ordenarMensagens = (mensagens) => {

    if (mensagens.length === 1) return mensagens;

    for (var i = 0; i < mensagens.length; i++) {

        // Last i elements are already in place  
        for (var j = 0; j < (mensagens.length - i - 1); j++) {

            // Checking if the item at present iteration 
            // is greater than the next iteration
            if (mensagens[j].createdAt > mensagens[j + 1].createdAt) {

                // If the condition is true then swap them
                var temp = mensagens[j]
                mensagens[j] = mensagens[j + 1]
                mensagens[j + 1] = temp
            }
        }
    }

    return mensagens
}

const getHoursDiff = (startDate, endDate) => {
    const msInHour = 1000 * 60 * 60;

    const diff = Math.round(
        Math.abs(endDate.getTime() - startDate.getTime()) / msInHour,
    );
    return diff
}

const isExistInDepartament = (etiqueta, departamento_usuario) => {
    for (let departamento of etiqueta.departamento) {
        if (departamento_usuario.includes(departamento)) {
            return true
        }
    }
    return false
}

export {
    delay,
    isUserAuthenticated,
    setLoggedInUser,
    getLoggedInUser,
    formaterMessage,
    formaterConversa,
    getTypeFile,
    replaceBold,
    replaceNewLine,
    replaceURLs,
    ordenarMensagens,
    getHoursDiff,
    isExistInDepartament,
    getFilter,
    setFilter
};