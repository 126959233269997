import {
    SET_FILTER, UPDATE_TAG, TOTAL_FILA, PAGE_FILA, NEXT_PAGE, MESSAGE_READ_CONFIRMATION, UPDATE_CONVERSA, REMOVE_ATENDENTE, SET_ATENDENTES, MESSAGE_SUCCESS, MESSAGE_ERRO, ADD_CONTACT, SET_FULL_CONTACT, REMOVE_USER, FILA_NOTIFICATION, CHAT_USER, ACTIVE_USER, FULL_USER, ADD_LOGGED_USER, CREATE_GROUP, ADD_FILA, FULL_FILA, ADD_TOAST, DELETE_FILA, SET_LOADER, ADD_NEW_MESSAGE, ADD_GROUP, SET_ATENDENTE_CONVERSA, CONVERSA_CLOSED, MESSAGE_DELETED, READ_MESSAGE, HAS_MORE, INIT_PAGE
} from './constants';
import { toast } from "react-toastify";
import { formaterMessage, getLoggedInUser, ordenarMensagens, getFilter, setFilter } from "../../helpers/authUtils";
import avatarDefault from "../../assets/images/users/user.png"
import config from "../../config"
import { showNotification } from '../../helpers/Notification'

// icons
import iconNewMessage from '../../assets/images/icons/text.png'
import iconFila from '../../assets/images/icons/people.png'

const INIT_STATE = {
    active_user: 0,
    page: 1,
    hasMore: true,
    users: [],
    groups: [],
    atendentes: [],
    contacts: [],
    count_fila: 0,
    fila: [],
    totalFila: 0,
    pageFila: 1,
    loader: false,
    toast: true,
    filter: getFilter(),
    current_atendente: getLoggedInUser()
};


const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_FILTER:
            setFilter(action.payload)
            return {
                ...state,
                hasMore: true,
                page: 1,
                active_user: 0,
                filter: action.payload
            }

        case NEXT_PAGE:
            return {
                ...state,
                page: state.page + 1
            }
        case INIT_PAGE:

            var copyAllUsers = [...state.users]
            copyAllUsers = copyAllUsers.slice(0, 51)

            return {
                ...state,
                users: copyAllUsers,
                hasMore: copyAllUsers.length >= 50 ? true : false,
                page: 1
            }

        case HAS_MORE:

            return {
                ...state,
                hasMore: action.payload
            }

        case CHAT_USER:
            return { ...state };

        case ACTIVE_USER:
            return {
                ...state,
                active_user: action.payload
            };

        case SET_FULL_CONTACT:
            return {
                ...state,
                contacts: action.payload
            }

        case FULL_USER:
            return {
                ...state,
                users: action.payload
            };

        case ADD_LOGGED_USER:
            const newUser = action.payload
            return {
                ...state,
                users: [
                    newUser, ...state.users,
                ],
                active_user: 0
            };

        case TOTAL_FILA:
            return {
                ...state,
                totalFila: action.payload,
            }

        case ADD_FILA:

            const newFila = action.payload;

            var index_new_element = state.fila.findIndex(row => row._id == newFila._id)

            if (index_new_element != -1) {
                return {
                    ...state
                };
            }

            toast.warning("Nova conversa aguardando na fila!")

            showNotification({
                title: 'Nova conversa!',
                body: `${newFila.name} está esperando por atendimento!`,
                icon: iconFila
            })

            var audio = document.getElementById("audio-notification")

            var playAudio = audio.play()

            if (playAudio != undefined) {
                playAudio.then(success => {
                    console.log("Iniciou Automático")
                }).catch(err => {
                    console.log("não iniciou")
                })
            }


            return {
                ...state,
                count_fila: state.count_fila + 1,
                fila: [...state.fila, newFila]
            }

        case DELETE_FILA:

            var new_fila = state.fila.filter(row => row._id != action.payload)

            return {
                ...state,
                count_fila: new_fila.length,
                fila: new_fila
            }

        case ADD_TOAST:
            return {
                ...state,
                toast: true
            }

        case FULL_FILA:
            return {
                ...state,
                fila: action.payload.fullFila,
                totalFila: action.payload.total
            }

        case CREATE_GROUP:
            const newGroup = action.payload
            return {
                ...state, groups: [
                    ...state.groups, newGroup
                ]
            }
        case PAGE_FILA:

            return {
                ...state,
                pageFila: action.payload
            }
        case FILA_NOTIFICATION:
            var now = new Date()
            var update_time_fila = state.fila.map(row => {
                var entrou_na_fila = new Date(row.data_hora_entrou_fila)
                var tempo_espera_millis = now.getTime() - entrou_na_fila.getTime()
                row.tempo_espera = Math.floor(tempo_espera_millis / 60000)
                return row
            })

            if (state.fila.length > 0) {
                toast.info(`Você tem ${state.totalFila} conversa${state.fila.length == 1 ? 's' : ''} esperando na fila!`)
                var audio = document.getElementById("audio-notification")

                var playAudio = audio.play()

                if (playAudio != undefined) {
                    playAudio.then(success => {
                        console.log("Iniciou Automático")
                    }).catch(err => {
                        console.log("não iniciou")
                    })
                }

            }

            return { ...state, fila: update_time_fila }
        case REMOVE_USER:

            var new_users = state.users.filter(user => user._id != action.payload)

            return {
                ...state,
                active_user: 0,
                users: new_users
            }
        case SET_LOADER:
            return {
                ...state,
                loader: !state.loader
            }

        case ADD_NEW_MESSAGE:

            var data = action.payload;

            const { conversa } = data;

            // copia os usuarios/chats dos states
            let copyallUsers = [...state.users];

            // se tem atendente pega o id se não fica como null
            var atendente = data.atendente ? data.atendente._id : null

            // verifica se existe o chat
            var index_chat = copyallUsers.findIndex(conversa => {
                // se o chat é de equipe, então procura tanto pelo chatId ou pelo atendente
                if (data.conversa.isEquipe) {
                    return (conversa.chatId === data.chatId) || (conversa.chatId == atendente) || conversa.atendente == data.chatId || conversa.atendente == atendente
                } else {
                    // se não é equipe procura apenas por chatId.
                    return conversa.chatId === data.chatId && conversa.isEquipe == false && conversa.departamento_responsavel_atendimento._id == data.conversa.departamento_responsavel_atendimento._id
                }
            })


            // caso o chat não seja encontrado, é criado um novo chat
            if (index_chat === -1) {
                console.log("chat não encontrado!")

                // caso seja equipe ou adm é criado uma nova conversa
                if (conversa.isEquipe || data.isAdmin) {
                    console.log("é equipe, criando uma nova conversa!")
                    var nome;
                    var email;
                    var informacoesAdicionais;
                    var contato_lead_id;

                    if (conversa.contato) {
                        nome = conversa.contato.name
                        email = conversa.contato.email
                        contato_lead_id = conversa.contato._id
                        informacoesAdicionais = conversa.contato.informacoesAdicionais
                    } else if (conversa.lead) {
                        nome = conversa.lead.nome
                        email = conversa.lead.email
                        contato_lead_id = conversa.lead._id
                        informacoesAdicionais = conversa.lead.informacoesAdicionais
                    }

                    if (!nome) {
                        nome = conversa.pushName || conversa.chatId
                    }

                    var conversa_formated = {
                        id: state.users.length + 1,
                        chatId: conversa.isEquipe ? data.atendente ? data.atendente._id == data.currentAtendenteId ? conversa.chatId : data.atendente._id : conversa.chatId : conversa.chatId,
                        _id: conversa._id,
                        name: conversa.isEquipe ? data.atendente._id == data.currentAtendenteId ? state.current_atendente.nome : data.atendente.nome : nome,
                        profilePicture: conversa.isEquipe ? data.atendente._id == data.currentAtendenteId ? state.current_atendente.photo : data.atendente.photo : conversa.profilePicture || avatarDefault,
                        status: conversa.status,
                        unRead: 0,
                        status_profile: "",
                        origem: conversa.origem,
                        roomType: "contact",
                        isGroup: false,
                        email,
                        contato_lead_id,
                        atendente: data.atendente,
                        isEquipe: conversa.isEquipe,
                        fila: conversa.fila,
                        protocolo: conversa.protocolo,
                        informacoesAdicionais,
                        etiquetas: conversa.etiquetas,
                        messages: [{ isToday: true }]
                    }

                    copyallUsers.push(conversa_formated)
                    index_chat = state.users.length

                } else {
                    return {
                        ...state
                    }
                }

            };

            // verifica se a mensagen 
            var chatMessages = copyallUsers[index_chat].messages

            var index_message = chatMessages.findIndex(message => message._id == data._id)

            if (index_message !== -1) {
                console.log("mensagem já existe")
                return {
                    ...state,
                    users: copyallUsers
                }
            }


            var mensagem = formaterMessage(data, copyallUsers[index_chat].messages.length == 0 ? 0 : copyallUsers[index_chat].messages.length + 1, conversa, data.isAdmin)

            //caso a mensagem venha do bot, faz a ordenacao das mensagens.
            copyallUsers[index_chat].messages = data.autor.includes("bot") ? ordenarMensagens([...chatMessages, mensagem]) : [...chatMessages, mensagem]

            copyallUsers[index_chat].unRead = mensagem.userType == "receive" ? copyallUsers[index_chat].unRead + 1 : copyallUsers[index_chat].unRead
            copyallUsers[index_chat].isTyping = false;
            copyallUsers[index_chat].departamento_responsavel_atendimento = conversa.departamento_responsavel_atendimento
            copyallUsers[index_chat].fila = conversa.fila;

            if (!conversa.isEquipe) {
                var nome;
                var email;
                var informacoesAdicionais;
                var contato_lead_id;

                if (conversa.contato) {
                    nome = conversa.contato.name
                    email = conversa.contato.email
                    contato_lead_id = conversa.contato._id
                    informacoesAdicionais = conversa.contato.informacoesAdicionais
                } else if (conversa.lead) {
                    nome = conversa.lead.nome
                    email = conversa.lead.email
                    contato_lead_id = conversa.lead._id
                    informacoesAdicionais = conversa.lead.informacoesAdicionais
                }

                if (!nome) {
                    nome = conversa.pushName || conversa.chatId
                }

                copyallUsers[index_chat].profilePicture = conversa.profilePicture ? config.STORAGE_URL + conversa.profilePicture : avatarDefault
                copyallUsers[index_chat].name = nome
                copyallUsers[index_chat].email = email
                copyallUsers[index_chat].contato_lead_id = contato_lead_id
                copyallUsers[index_chat].informacoesAdicionais = informacoesAdicionais

            }

            var chat = copyallUsers[index_chat]
            // console.log(chat)

            // busco o user anterior
            var active_user = copyallUsers[state.active_user]

            copyallUsers.splice(index_chat, 1)

            copyallUsers.unshift(chat)

            // atualiza o active_user, para continuar na mesma conversa, procuro pelo id e pego o novo index da conversa que já estava aberta
            active_user = copyallUsers.findIndex(row => row._id == active_user._id)

            if ((
                (document.visibilityState == 'hidden' || active_user != index_chat)
                && chat.atendente
                && chat.atendente._id == state.current_atendente._id
                && mensagem.userType == "receive")
                || (!chat.atendente && mensagem.userType == "receive")) {

                var audio = document.getElementById("audio-notification")

                var playAudio = audio.play()

                if (playAudio != undefined) {
                    playAudio.then(success => {
                        console.log("Iniciou Automático")
                    }).catch(err => {
                        console.log("não iniciou")
                    })
                }

                // envia notifica de mensagem recebida
                showNotification({
                    title: 'Nova mensagem!',
                    body: nome ? `${nome} enviou uma nova mensagem!` : 'Você recebeu uma mensagem!',
                    icon: iconNewMessage
                })
            }

            return {
                ...state,
                users: copyallUsers,
                active_user: active_user
            }

        case ADD_GROUP:

            // verifico se existe o chat
            var index_chat = state.users.findIndex(conversa => conversa.chatId === action.payload.chatId)

            action.payload.userName = action.payload.full_message.participant;

            if (action.payload.conversa.isGroup) {
                if (action.payload.atendente) {
                    action.payload.userName = action.payload.atendente.nome
                    action.payload.profilePicture = action.payload.atendente.photo
                } else {
                    action.payload.userName = action.payload.full_message.participant || action.payload.full_message.pushName
                }
            }

            // se não existir é criado um novo chat
            if (index_chat === -1) {
                var conversa_formated = {
                    id: state.users.length + 1,
                    chatId: action.payload.conversa.chatId,
                    _id: action.payload.conversa._id,
                    name: action.payload.conversa.pushName,
                    profilePicture: avatarDefault,
                    status_profile: "",
                    status: action.payload.conversa.status,
                    unRead: 0,
                    roomType: "contact",
                    isGroup: true,
                    origem: action.payload.conversa.origem,
                    departamento_responsavel_atendimento: action.payload.conversa.departamento_responsavel_atendimento,
                    messages: [formaterMessage(action.payload, 0, action.payload.conversa)]
                }

                return {
                    ...state,
                    users: [...state.users, conversa_formated]
                }

            }
            // se existir apenas é adicionado a mensagem ao chat
            else {

                var copyAllusers = [...state.users]
                // verifica se a mensagen esta duplicada
                var index_message = copyAllusers[index_chat].messages.findIndex(message => message._id == action.payload._id)

                if (index_message !== -1) {
                    console.log("mensagem já existe")
                    return {
                        ...state
                    }
                }

                copyAllusers[index_chat].messages = [...copyAllusers[index_chat].messages, formaterMessage(action.payload, copyAllusers[index_chat].messages.length + 1, action.payload.conversa)]
                copyAllusers[index_chat].unRead = copyAllusers[index_chat].unRead + 1

                return {
                    ...state,
                    users: copyAllusers
                }

            }
        case ADD_CONTACT:
            var new_contact = action.payload;

            var index_contact = state.contacts.findIndex(row => row.id === new_contact.id)

            if (index_contact === -1) {
                return {
                    ...state,
                    contacts: [...state.contacts, new_contact]
                }
            }

            var copyAllContact = [...state.contacts]
            copyAllContact[index_contact] = new_contact

            return {
                ...state,
                contacts: copyAllContact
            }

        case MESSAGE_ERRO:

            var copyAllUsers = [...state.users]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload.conversa)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            var copyMessages = [...copyAllUsers[index_conversa].messages]

            var index_mensagem = copyMessages.findIndex(mensagem => mensagem._id == action.payload.mensagem)

            if (index_mensagem === -1) {
                console.log("mensagem nao encontrada")
                return {
                    ...state
                }
            }

            copyMessages[index_mensagem].isError = true;

            copyAllUsers[index_conversa].messages = copyMessages;

            return {
                ...state,
                users: copyAllUsers
            }

        case MESSAGE_READ_CONFIRMATION:
            var copyAllUsers = [...state.users]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload.conversa)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            var copyMessages = [...copyAllUsers[index_conversa].messages]

            var index_mensagem = copyMessages.findIndex(mensagem => mensagem._id == action.payload.mensagem)

            if (index_mensagem === -1) {
                console.log("mensagem nao encontrada")
                return {
                    ...state
                }
            }

            copyMessages[index_mensagem].isRead = true;

            copyAllUsers[index_conversa].messages = copyMessages;

            return {
                ...state,
                users: copyAllUsers
            }

        case MESSAGE_SUCCESS:

            var copyAllUsers = [...state.users]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload.conversa)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            var copyMessages = [...copyAllUsers[index_conversa].messages]

            var index_mensagem = copyMessages.findIndex(mensagem => mensagem._id == action.payload.mensagem)

            if (index_mensagem === -1) {
                console.log("mensagem nao encontrada")
                return {
                    ...state
                }
            }

            copyMessages[index_mensagem].isError = false;

            copyAllUsers[index_conversa].messages = copyMessages;

            return {
                ...state,
                users: copyAllUsers
            }

        case SET_ATENDENTES:
            return {
                ...state,
                atendentes: action.payload
            }

        case REMOVE_ATENDENTE:

            var copyAllUsers = [...state.users]

            copyAllUsers[action.payload.active_user].atendente = null
            copyAllUsers[action.payload.active_user].fila = action.payload.fila

            return {
                ...state,
                users: copyAllUsers
            }
        case SET_ATENDENTE_CONVERSA:

            // verifica se o usuário é admin
            // caso o usuário seja admin apenas procura a conversa e atualiza o atendente
            // caso não seja admin ou se o departamento não faz parte do admin a conversa é removida 
            var data = action.payload
            var copyAllUsers = [...state.users]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == data._id)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            // verifica se o departamento do atendente que assumiu faz parte dos departamentos do admin que está visualizando a conversa
            var isDepartamentoAdmin = false;

            // caso pelo menos um departamento atenda, significa que o atendente é supersvisionado pelo admin que está vendo a conversa
            for (let dep of state.current_atendente.departamento) {
                if (data.atendente.departamento.includes(dep._id)) {
                    isDepartamentoAdmin = true;
                    break
                }
            }

            if (data.isAdmin && isDepartamentoAdmin) {

                copyAllUsers[index_conversa].atendente = data.atendente

                toast.info(`${data.atendente.nome} assumiu a conversa!`)

                return {
                    ...state,
                    users: copyAllUsers
                }

            } else {
                copyAllUsers.splice(index_conversa, 1)

                toast.info(`${data.atendente.nome} assumiu a conversa!`)

                return {
                    ...state,
                    users: copyAllUsers
                }
            }
        case CONVERSA_CLOSED:

            var copyAllUsers = [...state.users]

            var oldUserActive = copyAllUsers[state.active_user]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            copyAllUsers.splice(index_conversa, 1)

            var indexNewActiveUser = copyAllUsers.findIndex(conversa => conversa._id == oldUserActive._id)

            return {
                ...state,
                active_user: indexNewActiveUser == -1 ? 0 : indexNewActiveUser, // se for -1 significa que o active user era o que estava ativo.
                users: copyAllUsers
            }

        case MESSAGE_DELETED:

            var copyAllUsers = [...state.users]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload.conversa)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            var copyMessages = [...copyAllUsers[index_conversa].messages]

            var index_mensagem = copyMessages.findIndex(mensagem => mensagem._id == action.payload.mensagem)

            if (index_mensagem === -1) {
                console.log("mensagem nao encontrada")
                return {
                    ...state
                }
            }

            copyMessages[index_mensagem].isRemoved = true;

            copyAllUsers[index_conversa].messages = copyMessages;

            return {
                ...state,
                users: copyAllUsers
            }

        case READ_MESSAGE:

            var copyAllUsers = [...state.users]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload.conversa)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            copyAllUsers[index_conversa].unRead = 0

            return {
                ...state,
                users: copyAllUsers
            }

        case UPDATE_CONVERSA:

            var copyAllUsers = [...state.users]

            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload.conversa)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            copyAllUsers[index_conversa].name = action.payload.nome;
            copyAllUsers[index_conversa].departamento_responsavel_atendimento = action.payload.departamento_responsavel
            copyAllUsers[index_conversa].email = action.payload.email

            return {
                ...state,
                users: copyAllUsers
            }
        case UPDATE_TAG:
            var copyAllUsers = [...state.users]
            var index_conversa = copyAllUsers.findIndex(conversa => conversa._id == action.payload.id_conversa)

            if (index_conversa === -1) {
                console.log("conversa nao encontrada")
                return {
                    ...state
                }
            }

            copyAllUsers[index_conversa].etiquetas = action.payload.etiquetas;

            return {
                ...state,
                users: copyAllUsers
            }
        default: return { ...state };
    }
}

export default Chat;